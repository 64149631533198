import '@babel/polyfill'

import '@mdi/font/css/materialdesignicons.min.css'
import * as Sentry from '@sentry/vue'
import Vue from 'vue'
import Toasted from 'vue-toasted'
import VueTour from 'vue-tour'
import 'vue-tour/dist/vue-tour.css'

import { ServerTable } from 'vue-tables-2'
import numeral from 'numeral'
import VueHighcharts from 'vue-highcharts'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { createProvider } from './vue-apollo'

// eslint-disable-next-line import/extensions
import './plugins/vee-validate.js'
// eslint-disable-next-line import/extensions
import './plugins/index.js'

import './assets/scss/styles.scss'
import './assets/styles/roboto_material_icons.css'

Vue.config.productionTip = false
Vue.use(Toasted, { duration: 2000 })

Vue.use(ServerTable, {
  debounce: 500,
  sortIcon: {
    base: 'mdi',
    up: 'mdi-sort-ascending',
    down: 'mdi-sort-descending',
    is: 'mdi-sort',
  },
  skin: 'table-striped table-sm table-bordered table-hover',
})

Vue.use(VueTour)

Vue.filter('number', value => {
  if (Number.isInteger(value)) {
    return numeral(+value)
      .format()
      .replace(/,/g, ' ')
  }
  return value
})

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
})

new Vue({
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),
  render: h => h(App),
}).$mount('#app')

Vue.use(VueHighcharts)
